import * as React from 'react';
import {useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import {HttpStatusCode} from 'axios';
import {getEventList} from '../api/event';
import {EventDto} from '../api/dto/event';
import {TFunction} from 'i18next';
import {IconButton, useMediaQuery, useTheme} from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function EventList({
  t,
}: {
  t: TFunction<'translation', undefined>;
}) {
  const [events, setEvents] = useState<EventDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState<EventDto | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchEvents = async (currentPage: number) => {
    setLoading(true);
    try {
      const eventList = await getEventList({limit: 10, page: currentPage});
      if (eventList?.status === HttpStatusCode.Ok) {
        if (currentPage === 1) {
          setEvents(eventList?.data?.items);
        } else {
          setEvents(prevEvents => [
            ...prevEvents,
            ...(eventList?.data?.items || []),
          ]);
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(1); // 初次加載第一頁
  }, []);

  // 點擊更多事件時調用
  const loadMore = () => {
    const nextPage = page + 1;
    fetchEvents(nextPage);
    setPage(nextPage);
  };

  // 點擊行顯示詳細資料
  const handleRowClick = (event: EventDto) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  return (
    <React.Fragment>
      <Title>{t('dashboardAccountRecentEvent')}</Title>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{t('dashboardAccountEventDate')}</TableCell>
            <TableCell>{t('dashboardAccountEventTitle')}</TableCell>
            <TableCell>{t('dashboardAccountEventMessage')}</TableCell>
            <TableCell>{t('dashboardAccountEventEntity')}</TableCell>
            <TableCell>{t('dashboardAccountEventMethod')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            events &&
            events.map(event => (
              <TableRow
                key={event.id}
                hover
                onClick={() => handleRowClick(event)}
                style={{cursor: 'pointer'}}
              >
                <TableCell>
                  {new Date(event.createdAt).toLocaleString()}
                </TableCell>
                <TableCell>{event.title}</TableCell>
                <TableCell>{event.message}</TableCell>
                <TableCell>{event.entityName}</TableCell>
                <TableCell>{event.method}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Link color='primary' href='#' onClick={preventDefault} sx={{mt: 3}}>
        <span onClick={loadMore}>{t('dashboardAccountSeeMoreEvent')}</span>
      </Link>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth='md'
        fullScreen={fullScreen}
      >
        <DialogActions sx={{justifyContent: 'flex-end', padding: '8px'}}>
          <IconButton onClick={handleCloseDialog} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogTitle>{t('dashboardAccountEventDetails')}</DialogTitle>
        <DialogContent>
          {selectedEvent && (
            <>
              <DialogContentText>
                <strong>{t('dashboardAccountEventTitle')}:</strong>{' '}
                {selectedEvent.title}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventMessage')}:</strong>{' '}
                {selectedEvent.message}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventUserId')}:</strong>{' '}
                {selectedEvent.userId}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventEntity')}:</strong>{' '}
                {selectedEvent.entityName}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventEntityId')}:</strong>{' '}
                {selectedEvent.entityId}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventMethod')}:</strong>{' '}
                {selectedEvent.method}
              </DialogContentText>
              <DialogContentText>
                <strong>{t('dashboardAccountEventDate')}:</strong>{' '}
                {new Date(selectedEvent.createdAt).toLocaleString()}
              </DialogContentText>
            </>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
