import {AxiosResponse} from 'axios';
import {EventDto, GetEventListDto} from './dto/event';
import callApi from './index';

export const getEventList = (
  data: GetEventListDto,
): Promise<AxiosResponse<{items: EventDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/event/list`, options);
};
