import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {createRole, deleteRole, getRoleList, updateRole} from '../../api/role';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Alert, AlertColor, Chip, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import EditTablePermissionColumn from '../../components/EditTable/EditTablePermissionColumn';

dayjs.extend(utc);

function validate(data: any) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function RoleContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'createdAt',
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
      {
        accessorKey: 'rolePlatformPermissions',
        header: 'permissions',
        enableEditing: false,
        Edit: ({cell, row, column}) => (
          <EditTablePermissionColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
          />
        ),
        Cell: ({cell}) => (
          <>
            {cell.getValue<any>()?.map(
              (d: {
                platformPermission: {
                  scope?: string;
                  action?: string;
                  platform?: {name: string};
                };
                scope?: string;
                action?: string;
              }) => (
                <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
                  <Chip
                    label={`${d.platformPermission?.scope || d?.scope}:${
                      d.platformPermission?.action || d?.action
                    }`}
                  />
                </Box>
              ),
            )}
          </>
        ),
      },
    ],
    [validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuRole')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTableRoleDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createRole,
          updateApi: updateRole,
          getListApi: getRoleList,
          deleteApi: deleteRole,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardRole() {
  return <Dashboard element={RoleContent()}></Dashboard>;
}
