import {TextField, IconButton} from '@mui/material';
import {TFunction} from 'i18next';
import {MRT_Cell, MRT_Column, MRT_Row} from 'material-react-table';
import React, {useEffect} from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'; // 用於格式化的圖標

export default function EditTableJsonColumn({
  t,
  cell,
  row,
  column,
  defaultValue,
  placeHolder,
}: {
  t: TFunction<'translation', undefined>;
  cell: MRT_Cell<any, unknown>;
  row: MRT_Row<any>;
  column: MRT_Column<any, unknown>;
  defaultValue?: string;
  placeHolder?: string;
}) {
  const value = cell.getValue();

  const [editValue, setEditValue] = React.useState(() =>
    value ? JSON.stringify(value, null, 2) : defaultValue,
  );

  useEffect(() => {
    row._valuesCache[column.id] = editValue;
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditValue(event.target.value);

    try {
      const parsedValue = JSON.parse(event.target.value);
      row._valuesCache[column.id] = parsedValue;
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  // 格式化 JSON
  const handlePrettyJson = () => {
    try {
      const parsedValue = JSON.parse(editValue as string);
      const prettyJson = JSON.stringify(parsedValue, null, 2);
      setEditValue(prettyJson);
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <TextField
        multiline
        minRows={4}
        maxRows={8}
        fullWidth
        value={editValue}
        placeholder={placeHolder}
        onChange={handleChange}
        helperText={t('validateJSON')}
        error={(() => {
          try {
            JSON.parse(editValue as string);
            return false;
          } catch {
            return true;
          }
        })()}
      />
      <IconButton
        onClick={handlePrettyJson}
        color='primary'
        aria-label='format-json'
        style={{marginLeft: '8px'}}
      >
        <FormatAlignLeftIcon />
      </IconButton>
    </div>
  );
}
