import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {ReactElement, useEffect, useState} from 'react';
import {HttpStatusCode} from 'axios';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  LinearProgress,
} from '@mui/material';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {getPlatformUsage} from '../../../api/platform';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

interface IAccountInfo {
  platformCount: number;
  userCount: number;
  roleCount: number;
  permissionCount: number;
  planCount: number;
  ruleObj: {
    maxLimitPlanCount: number;
    maxLimitPlatformCount: number;
    maxLimitPlatformPermissionCount: number;
    maxLimitRoleCount: number;
    maxLimitUserCount: number;
  };
}

function Card(title: string, count: number, maxLimit: number) {
  const progress = (count / maxLimit) * 100;
  const statusColor =
    progress < 80 ? 'success' : progress < 100 ? 'warning' : 'error';

  return (
    <Box
      sx={{
        p: 3,
        minWidth: 300,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.default',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Box sx={{color: 'text.secondary'}}>{title}</Box>
      <Box
        sx={{
          color: 'text.primary',
          fontSize: '2.125rem',
          fontWeight: 'medium',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{count}</div>
        <div style={{color: 'text.secondary'}}>/ {maxLimit}</div>
      </Box>
      <LinearProgress
        variant='determinate'
        value={progress}
        sx={{
          marginTop: 1,
          height: 8,
          borderRadius: 4,
          backgroundColor: 'background.paper',
          '& .MuiLinearProgress-bar': {
            backgroundColor:
              statusColor === 'success'
                ? 'success.main'
                : statusColor === 'warning'
                ? 'warning.main'
                : 'error.main',
          },
        }}
      />
    </Box>
  );
}

function PlatformUsage(): ReactElement {
  const {t} = useTranslation();
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>();
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  useEffect(() => {
    getPlatformUsage()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(d.data));
      } else {
        setAccountInfo(d.data.data);
      }
    });
  }, []);

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardPlatformUsagePageTitle')}
        </Typography>
        <Typography variant='body1' color='text.secondary'></Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {accountInfo &&
            Card(
              t('dashboardMenuPlatform'),
              accountInfo.platformCount,
              accountInfo.ruleObj.maxLimitPlatformCount,
            )}
        </Grid>
        <Grid item xs={6}>
          {accountInfo &&
            Card(
              t('dashboardMenuUser'),
              accountInfo.userCount,
              accountInfo.ruleObj.maxLimitUserCount,
            )}
        </Grid>
        <Grid item xs={6}>
          {accountInfo &&
            Card(
              t('dashboardMenuRole'),
              accountInfo.roleCount,
              accountInfo.ruleObj.maxLimitRoleCount,
            )}
        </Grid>
        <Grid item xs={6}>
          {accountInfo &&
            Card(
              t('dashboardMenuPermission'),
              accountInfo.permissionCount,
              accountInfo.ruleObj.maxLimitPlatformPermissionCount,
            )}
        </Grid>
        <Grid item xs={6}>
          {accountInfo &&
            Card(
              t('dashboardMenuPlan'),
              accountInfo.planCount,
              accountInfo.ruleObj.maxLimitPlanCount,
            )}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatformUsage() {
  return <Dashboard element={PlatformUsage()}></Dashboard>;
}
