import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {createPlan, deletePlan, getPlanList, updatePlan} from '../../api/plan';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  Switch,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import EditTableJsonColumn from '../../components/EditTable/EditTableJsonColumn';
import EditTableBooleanColumn from '../../components/EditTable/EditTableBooleanColumn';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
dayjs.extend(utc);

function validate(data: any) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function PlanContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 60,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
        size: 10,
      },
      {
        accessorKey: 'i18nPriceObj',
        header: 'i18nPriceObj',
        enableEditing: true,
        size: 50,
        muiEditTextFieldProps: {
          required: true,
          defaultValue: {'zh-TW': {}},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              i18nPriceObj: undefined,
            }),
        },

        Edit: ({cell, row, column}) => (
          <EditTableJsonColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
            placeHolder='{
                 "zh-TW": {
                "specialPrice": null,
                "price": 100,
                "currency": "TWD"
              }
            }'
          />
        ),
        Cell: ({cell}) => {
          const i18nPriceObj =
            cell.getValue<
              Record<
                string,
                {price: number; currency: string; specialPrice?: number}
              >
            >();

          return (
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
              {i18nPriceObj && Object.keys(i18nPriceObj).length > 0 ? (
                Object.entries(i18nPriceObj).map(([lang, priceInfo]) => (
                  <Box key={lang}>
                    <Typography variant='body2' color='text.secondary'>
                      {`${lang}: ${
                        priceInfo.currency
                      } ${priceInfo?.price?.toFixed(2)}`}
                    </Typography>
                    {priceInfo.specialPrice !== null && (
                      <Typography
                        variant='body2'
                        color='error'
                        component='span'
                      >
                        {`Special: ${
                          priceInfo.currency
                        } ${priceInfo?.specialPrice?.toFixed(2)}`}
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant='body2' color='text.secondary'>
                  N/A
                </Typography>
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: 'ruleObj',
        header: 'ruleObj',
        show: false,
        muiEditTextFieldProps: {
          required: true,
          defaultValue: {},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              ruleObj: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableJsonColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
            placeHolder='{
                "connectAmount": 10000,
                "isVIP": true
            }'
          />
        ),

        size: 50,
      },

      {
        accessorKey: 'isShow',
        header: 'isShow',
        editSelectOptions: ['true', 'false'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,

          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isShow: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableBooleanColumn t={t} cell={cell} row={row} column={column} />
        ),
        Cell: ({cell}) => (
          <Switch
            checked={cell.getValue<boolean>()}
            inputProps={{'aria-label': 'controlled'}}
            color='primary'
            disabled
          />
        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'createdAt',
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuPlan')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTablePlanDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createPlan,
          updateApi: updatePlan,
          getListApi: getPlanList,
          deleteApi: deletePlan,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlan() {
  return <Dashboard element={PlanContent()}></Dashboard>;
}
