import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {getPlatformById} from '../api/platform';
import {getUserById} from '../api/user';
import {TFunction} from 'i18next';
import {UserDto} from '../api/dto/user';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import {DialogActions} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function PlatformPlan({
  t,
}: {
  t: TFunction<'translation', undefined>;
}) {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<UserDto | null>(null);
  const [hasPlatformAccess, setHasPlatformAccess] = useState(true);
  const [hasUserAccess, setHasUserAccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false); // For modal
  const platformId = localStorage.getItem('platformId') || '';

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const fetchPlatform = async () => {
      try {
        const platformResponse = await getPlatformById(platformId);
        if (platformResponse?.data?.data) {
          setUserId(platformResponse.data.data.userId);
        } else {
          setHasPlatformAccess(false);
        }
      } catch (error) {
        console.error('Error fetching platform data:', error);
        setHasPlatformAccess(false);
      }
    };

    fetchPlatform();
  }, [platformId]);

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!userId) return;
      try {
        const userResponse = await getUserById(userId);
        if (userResponse?.data?.data) {
          setUser(userResponse.data.data);
        } else {
          setHasUserAccess(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setHasUserAccess(false);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPlan();
  }, [userId]);

  // Loading state with transition animation
  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='200px'
      >
        <CircularProgress />
      </Box>
    );
  }

  // Plan color setup with icon
  const getPlanColor = (plan: string) => {
    switch (plan) {
      case 'Basic':
        return {color: 'green', icon: <CheckCircleIcon />};
      case 'Pro':
        return {color: 'blue', icon: <CheckCircleIcon />};
      case 'Premium':
        return {color: 'gold', icon: <CheckCircleIcon />};
      case 'Free':
        return {color: 'gray', icon: <CancelIcon />};
      default:
        return {color: 'black', icon: <CancelIcon />};
    }
  };

  return (
    <React.Fragment>
      <Title>{t('dashboardAccountPlatformPlanTitle')}</Title>
      {hasPlatformAccess ? (
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <IconButton onClick={handleOpenModal}>
            <AccountCircleIcon />
          </IconButton>
          <Typography component='p' variant='h6'>
            {t('dashboardAccountPlatformPlanOnwer')}: {user?.username || 'N/A'}
          </Typography>
        </Box>
      ) : (
        <Tooltip title='No access to platform'>
          <IconButton color='error'>
            <ErrorOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      {hasPlatformAccess && user?.userPlan?.plan && (
        <Typography
          component='p'
          variant='h6'
          sx={{
            color: getPlanColor(user?.userPlan?.plan.name).color,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <IconButton disabled>
            {getPlanColor(user?.userPlan?.plan.name).icon}
          </IconButton>
          {t('dashboardAccountPlatformPlanPlan')}:{' '}
          {user?.userPlan?.plan.name || 'No Plan'}
        </Typography>
      )}

      {hasPlatformAccess && user?.userPlan?.expirationAt && (
        <Typography color='text.secondary' sx={{mt: 2}}>
          <IconButton disabled>
            <TimerOffIcon />
          </IconButton>
          {t('dashboardAccountPlatformPlanExpireDate')}:{' '}
          {new Date(user?.userPlan?.expirationAt).toLocaleDateString()}
        </Typography>
      )}

      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              minWidth: 600,
            }}
          >
            <DialogActions sx={{justifyContent: 'flex-end', padding: '8px'}}>
              <IconButton onClick={handleCloseModal} aria-label='close'>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <Typography variant='h6' component='h2'>
              {t('dashboardAccountPlatformPlanDetails')}
            </Typography>
            <Typography sx={{mt: 2}}>
              {t('dashboardAccountPlatformPlanDetailsUsername')}:{' '}
              {user?.username}
            </Typography>
            <Typography>
              {t('dashboardAccountPlatformPlanDetailsEmail')}:{' '}
              {user?.email || 'N/A'}
            </Typography>
            <Typography>
              {t('dashboardAccountPlatformPlanDetailsPhone')}:{' '}
              {user?.phoneNumber}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
