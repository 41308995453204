import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
  validateUrl,
} from '../../components/MaterialTable';
import {
  createPlatform,
  deletePlatform,
  getPlatformList,
  updatePlatform,
} from '../../api/platform';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  Switch,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import EditTableBooleanColumn from '../../components/EditTable/EditTableBooleanColumn';
dayjs.extend(utc);

function validate(data: any) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
    cancelUrl: !validateUrl(data.cancelUrl) ? 'CancelUrl is Error' : '',
    returnUrl: !validateUrl(data.returnUrl) ? 'ReturnUrl is Error' : '',
    policyUrl: !validateUrl(data.policyUrl) ? 'PolicyUrl is Error' : '',
    webhookNotifyUrl: !validateUrl(data.webhookNotifyUrl)
      ? 'WebhookNotifyUrl is Error'
      : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function PlatformContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 60,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
        size: 10,
      },
      {
        accessorKey: 'returnUrl',
        header: 'ReturnUrl',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.returnUrl,
          helperText: validationErrors?.returnUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              returnUrl: undefined,
            }),
        },
        size: 30,
      },
      {
        accessorKey: 'cancelUrl',
        header: 'CancelUrl',
        show: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.cancelUrl,
          helperText: validationErrors?.cancelUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              cancelUrl: undefined,
            }),
        },
        size: 50,
      },
      {
        accessorKey: 'policyUrl',
        header: 'PolicyUrl',
        show: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.policyUrl,
          helperText: validationErrors?.policyUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              policyUrl: undefined,
            }),
        },
        size: 50,
      },
      {
        accessorKey: 'webhookNotifyUrl',
        header: 'WebhookNotifyUrl',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.webhookNotifyUrl,
          helperText: validationErrors?.webhookNotifyUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              webhookNotifyUrl: undefined,
            }),
        },
        size: 50,
      },
      {
        accessorKey: 'isActive',
        header: 'IsActive',
        editSelectOptions: ['true', 'false'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,
          error: !!validationErrors?.isActive,
          helperText: validationErrors?.isActive,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isActive: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableBooleanColumn t={t} cell={cell} row={row} column={column} />
        ),
        Cell: ({cell}) => (
          <Switch
            checked={cell.getValue<boolean>()}
            // onChange={handleChange}
            inputProps={{'aria-label': 'controlled'}}
            color='primary'
            disabled
          />
        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'createdAt',
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuPlatform')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTablePlatformDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createPlatform,
          updateApi: updatePlatform,
          getListApi: getPlatformList,
          deleteApi: deletePlatform,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatform() {
  return <Dashboard element={PlatformContent()}></Dashboard>;
}
