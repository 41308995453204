import React, {Dispatch, SetStateAction} from 'react';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_RowData,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {BaseRO} from '../api/dto';
import {HttpStatusCode} from 'axios';
import {useTranslation} from 'react-i18next';
import {ALERT_HIDE_DURATION} from '../constant';

export type MRT_ColumnDef_Add_Show<T extends MRT_RowData> = MRT_ColumnDef<T> & {
  show?: boolean;
};

type MaterialTableType = {
  createApi: Function;
  getListApi: Function;
  updateApi?: Function;
  deleteApi?: Function;
};

const DataElement = <T extends BaseRO>({
  props,
  columns,
  validate,
  setValidateErrors,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef_Add_Show<T>[];
  validate: Function;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
}) => {
  const {t} = useTranslation();
  const {createApi, updateApi, getListApi, deleteApi} = props;

  const {mutateAsync: createFunc, isPending: isCreatingFunc} =
    useCreate<T>(createApi);
  const {
    data: fetcheds = {data: {data: []}, status: HttpStatusCode.Conflict},
    isError: isLoadingError,
    isFetching: isFetching,
    isLoading: isLoading,
  } = useGetList(getListApi);

  const {mutateAsync: updateFunc, isPending: isUpdatingFunc} = useUpdate<T>(
    updateApi as any,
  );
  const {mutateAsync: deleteFunc, isPending: isDeletingFunc} = useDelete<T>(
    deleteApi as any,
  );

  const handleCreate: MRT_TableOptions<T>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});

    await createFunc(values as any);
    table.setCreatingRow(null);
  };

  const handleSave: MRT_TableOptions<T>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});
    await updateFunc(values as any);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = (row: MRT_Row<T>) => {
    if (window.confirm(t('dashboardConfirmMessage'))) {
      deleteFunc(row.original.id);
    }
  };

  const notShowColumnObj = columns.reduce((d, n) => {
    if (n.show === false) {
      return {...d, [n.accessorKey as string]: n.show};
    }

    return d;
  }, {});
  const table = useMaterialReactTable({
    columns,
    data:
      fetcheds.status === HttpStatusCode.Ok
        ? (fetcheds as any)?.data?.items
        : [],
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableColumnOrdering: true,
    enableHiding: true,
    getRowId: row => row.id,
    muiToolbarAlertBannerProps: isLoadingError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidateErrors({}),
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => setValidateErrors({}),
    onEditingRowSave: handleSave,
    renderCreateRowDialogContent: ({table, row, internalEditComponents}) => (
      <>
        <DialogTitle variant='h3'>{t('dashboardTableCreateBtn')}</DialogTitle>
        <DialogContent
          sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant='text' table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({table, row, internalEditComponents}) => (
      <>
        <DialogTitle variant='h4'>{t('dashboardTableEditBtn')} </DialogTitle>
        <DialogContent
          sx={{display: 'flex', flexDirection: 'column', gap: '1.5rem'}}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant='text' table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({row, table}) => (
      <Box sx={{display: 'flex', gap: '1rem'}}>
        {updateApi && (
          <Tooltip title='Edit'>
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {deleteApi && (
          <Tooltip title='Delete'>
            <IconButton
              color='error'
              onClick={() => openDeleteConfirmModal(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({table}) => (
      <Button
        variant='contained'
        onClick={() => {
          table.setCreatingRow(true);
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        {t('dashboardTableCreateBtn')}
      </Button>
    ),
    state: {
      isLoading: isLoading,
      isSaving: isCreatingFunc || isUpdatingFunc || isDeletingFunc,
      showAlertBanner: isLoadingError,
      showProgressBars: isFetching,
    },
    initialState: {
      columnVisibility: {
        ...notShowColumnObj,
      },
    },
  });

  if (
    fetcheds.status !== HttpStatusCode.Ok &&
    fetcheds.status !== HttpStatusCode.Conflict
  ) {
    return <>{t('dashboardAlertNotPermission')}</>;
  }

  return <MaterialReactTable table={table} />;
};

function useCreate<T extends BaseRO>(createApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const create = await createApi(data);
      if (create.status !== HttpStatusCode.Ok) {
        Promise.reject(create.data.message);
      }
      return Promise.resolve(create);
    },
    onMutate: (newData: T) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return [
          {
            ...newData,
            id: (Math.random() + 1).toString(36).substring(7),
          },
        ] as T[];
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useGetList<T extends BaseRO>(getListApi: Function) {
  return useQuery<{data: T[]; status: string}>({
    queryKey: ['datas'],
    queryFn: async () => {
      const getList = await getListApi({limit: 1000});
      return getList;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdate<T extends BaseRO>(updateApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const {id, ...rest} = data;
      const update = await updateApi(id, rest);
      if (update.status !== HttpStatusCode.Ok) {
        Promise.reject(update.data.message);
      }
      return Promise.resolve(update);
    },
    onMutate: (newInfo: T) => {
      queryClient.setQueryData(['datas'], (prevList: any) => {
        return prevList?.data?.items?.map((prev: T) =>
          prev.id === newInfo.id ? newInfo : prev,
        );
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useDelete<T extends BaseRO>(deleteApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const deleteRow = await deleteApi(id);
      if (deleteRow.status !== HttpStatusCode.Ok) {
        Promise.reject(deleteRow.data.message);
      }
      return Promise.resolve(deleteRow);
    },
    onMutate: (id: string) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return prev?.data?.items?.filter((data: T) => data.id !== id);
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

const queryClient = new QueryClient();
const TWithProviders = ({
  props,
  columns,
  validate,
  setValidateErrors,
  handleClick,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef<any>[];
  validate: Function;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
  handleClick: any;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataElement
        props={props}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidateErrors}
      />
    </QueryClientProvider>
  );
};

export default TWithProviders;

export const validateRequired = (value: string) => !!value?.length;
export const validateUrl = (value: string) =>
  !value?.length ||
  value
    .toLowerCase()
    .match(
      /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
    );
export const validateLengthMoreThanEqual = (value: string, min: number) =>
  value?.length >= min;
export const validateEmail = (email: string) =>
  !!email?.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const validateLoginType = (value: string) => {
  return (
    {
      email: true,
      google: true,
    }[value] ?? false
  );
};
