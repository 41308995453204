import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import {TFunction} from 'i18next';
import {Link} from '@mui/material';

export const mainListItems = (t: TFunction<'translation', undefined>) => (
  <React.Fragment>
    <Link color='#000' href='/dashboard' style={{textDecoration: 'none'}}>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuDashboard')} />
      </ListItemButton>
    </Link>
    <Link
      color='#000'
      href='/dashboard/platforms'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <CardTravelTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuPlatform')} />
      </ListItemButton>
    </Link>
    <Link color='#000' href='/dashboard/users' style={{textDecoration: 'none'}}>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuUser')} />
      </ListItemButton>
    </Link>
    <Link color='#000' href='/dashboard/roles' style={{textDecoration: 'none'}}>
      <ListItemButton>
        <ListItemIcon>
          <EngineeringTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuRole')} />
      </ListItemButton>
    </Link>
    <Link
      color='#000'
      href='/dashboard/permissions'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <AdminPanelSettingsTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuPermission')} />
      </ListItemButton>
    </Link>
    <Link color='#000' href='/dashboard/plans' style={{textDecoration: 'none'}}>
      <ListItemButton>
        <ListItemIcon>
          <FeaturedPlayListIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenuPlan')} />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const secondaryListItems = (t: TFunction<'translation', undefined>) => (
  <React.Fragment>
    <ListSubheader component='div' inset>
      {t('dashboardPlatformDetail')}
    </ListSubheader>
    <Link
      color='#000'
      href='/dashboard/platform/info'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardPlatformInfo')} />
      </ListItemButton>
    </Link>
    <Link
      color='#000'
      href='/dashboard/platform/usages'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <DisplaySettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardPlatformUsagePage')} />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const thirdListItems = (t: TFunction<'translation', undefined>) => (
  <React.Fragment>
    <ListSubheader component='div' inset>
      {t('dashboardAccountDetail')}
    </ListSubheader>
    <Link
      color='#000'
      href='/dashboard/userMe'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardUserMe')} />
      </ListItemButton>
    </Link>
    <Link
      color='#000'
      href='/dashboard/userAccount'
      style={{textDecoration: 'none'}}
    >
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={t('dashboardAccount')} />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
