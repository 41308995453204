import {Box, Tooltip, useTheme} from '@mui/material';
import {MRT_Cell} from 'material-react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
require('dayjs/locale/zh-tw');
require('dayjs/locale/zh-cn');
require('dayjs/locale/en');
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const CreatedAtColumn = ({cell}: {cell: MRT_Cell<any, unknown>}) => {
  const theme = useTheme();
  const timestamp = cell.getValue<string>();
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
    switch (localeI18n) {
      case 'zh-TW':
        dayjs.locale('zh-tw');
        break;
      case 'zh-CN':
        dayjs.locale('zh-cn');
        break;
      case 'en-US':
        dayjs.locale('en');
        break;
    }
  }, [localeI18n]);

  const relative = dayjs.utc(timestamp).fromNow();
  const formatted = dayjs.utc(timestamp).format('YYYY/MM/DD HH:mm:ssZ');

  // 根據時間範圍設置背景顏色
  const getBackgroundColor = (time: string) => {
    if (new Date(time).getTime() < Date.now() - 30 * 24 * 60 * 60 * 1000)
      // 超過 30 天
      return theme.palette.common.black;
    if (
      new Date(time).getTime() >= Date.now() - 30 * 24 * 60 * 60 * 1000 &&
      new Date(time).getTime() < Date.now() - 7 * 24 * 60 * 60 * 1000
    )
      return theme.palette.warning.dark;
    return theme.palette.success.dark;
  };

  return (
    <Tooltip title={formatted} arrow>
      <Box
        component='span'
        sx={{
          backgroundColor: getBackgroundColor(timestamp),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '12ch',
          p: '0.25rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {relative}
      </Box>
    </Tooltip>
  );
};
