import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Link,
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import {HttpStatusCode} from 'axios';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {getPlatformById} from '../../../api/platform';
import {getUserById} from '../../../api/user';
import {UserDto} from '../../../api/dto/user';
import {PlatformDto} from '../../../api/dto/platform';
import PolicyIcon from '@mui/icons-material/Policy';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PolicyInfoRow({label, value}: {label: string; value: string}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 3}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        {value ? (
          <Link href={value} target='_blank' rel='noopener noreferrer'>
            <IconButton aria-label='policy'>
              <PolicyIcon color='primary' />
            </IconButton>
          </Link>
        ) : (
          <Typography variant='body1' color='text.primary'>
            N/A
          </Typography>
        )}
      </Box>
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function PlatformInfoRow({
  label,
  value,
}: {
  label: string;
  value: string | number | boolean;
}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        <Typography
          variant='body1'
          color='text.primary'
          sx={{fontWeight: 'medium'}}
        >
          {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function InfoContent(): ReactElement {
  const {t} = useTranslation();
  const [platformData, setPlatformData] = useState<PlatformDto>();
  const [userData, setUserData] = useState<UserDto>();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  // 取得平台與用戶資料
  useEffect(() => {
    const fetchPlatformAndUser = async () => {
      try {
        const platformId = localStorage.getItem('platformId');
        if (!platformId) {
          handleClick('error', t('missingPlatformId'));
          setLoading(false);
          return;
        }

        const platformResponse = await getPlatformById(platformId);
        const platform = platformResponse && platformResponse.data;

        if (platform?.data?.userId) {
          const userResponse = await getUserById(platform.data.userId);
          setUserData(userResponse?.data.data);
        }

        setPlatformData(platform?.data);
      } catch (error) {
        handleClick(
          'error',
          t('failedToFetchData') || 'Failed to fetch platform data',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPlatformAndUser();
  }, [t]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='50vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 6, sm: 12},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 2, sm: 4},
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: {xs: 4, sm: 6},
          maxWidth: 600,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          component='h2'
          variant='h5'
          color='text.primary'
          sx={{mb: 4, textAlign: 'center'}}
        >
          {t('dashboardPlatformInfo')}
        </Typography>

        {platformData && (
          <>
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformName')}
              value={platformData.name}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformUID')}
              value={platformData.userId}
            />
            <PolicyInfoRow
              label={t('dashboardPlatformInfoPlatformPolicyUrl')}
              value={platformData?.policyUrl || ''}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformHash')}
              value={platformData.hash}
            />
          </>
        )}

        {userData?.userPlan && (
          <>
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanName')}
              value={userData?.userPlan?.plan?.name || ''}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanExpired')}
              value={new Date(
                userData?.userPlan?.expirationAt,
              ).toLocaleDateString()}
            />
          </>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default function DashboardPlatformInfo() {
  return <Dashboard element={<InfoContent />} />;
}
