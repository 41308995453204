import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Button,
  TextField,
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import {getUserMe, updateUserMe} from '../../api/user';
import {HttpStatusCode} from 'axios';
import {UserDto} from '../../api/dto/user';
import {ALERT_HIDE_DURATION} from '../../constant';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function UserInfoRow({
  label,
  value,
  isEditing,
  onChange,
  field,
  onEditClick,
  editable = true,
}: {
  label: string;
  value: string | number | boolean;
  isEditing: boolean;
  onChange?: (field: string, value: string) => void;
  field: string;
  onEditClick?: () => void;
  editable?: boolean;
}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 3}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        {isEditing ? (
          <TextField
            fullWidth
            variant='outlined'
            value={value}
            onChange={e => onChange?.(field, e.target.value)}
          />
        ) : (
          <Typography
            variant='body1'
            color='text.primary'
            sx={{fontWeight: 'medium'}}
          >
            {typeof value === 'boolean'
              ? value
                ? 'Yes'
                : 'No'
              : value || 'N/A'}
          </Typography>
        )}
      </Box>
      {!isEditing && editable && onEditClick && (
        <IconButton onClick={onEditClick} aria-label='edit'>
          <EditIcon />
        </IconButton>
      )}
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function AccountContent(): ReactElement {
  const {t} = useTranslation();
  const [userInfo, setUserInfo] = useState<UserDto | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editedInfo, setEditedInfo] = useState<Partial<UserDto>>({});
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleEditChange = (field: string, value: string) => {
    setEditedInfo(prev => ({...prev, [field]: value}));
  };

  const handleSave = async () => {
    try {
      const response = await updateUserMe(editedInfo);
      if (response && response.status === HttpStatusCode.Ok) {
        const userResponse = await getUserMe();
        if (userResponse) {
          setUserInfo(userResponse.data.data);
        }
        handleClick('success', t('updateSuccess'));
        setEditingField(null);
      } else {
        handleClick('error', JSON.stringify(response?.data));
      }
    } catch (error) {
      handleClick('error', 'Failed to update user information');
    }
  };

  useEffect(() => {
    getUserMe()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(d.data));
      } else {
        setUserInfo(d.data.data);
      }
    });
  }, []);

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 6, sm: 12},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 2, sm: 4},
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: {xs: 4, sm: 6},
          maxWidth: 500,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          component='h2'
          variant='h5'
          color='text.primary'
          sx={{mb: 4, textAlign: 'center'}}
        >
          {t('dashboardUserMe')}
        </Typography>

        {userInfo ? (
          <>
            <UserInfoRow
              label={t('dashboardUserMeEmail')}
              value={userInfo.email}
              isEditing={false}
              field='email'
              editable={false}
            />
            {['username', 'phoneNumber', 'gender'].map(field => (
              <UserInfoRow
                key={field}
                label={t(
                  `dashboardUserMe${
                    field.charAt(0).toUpperCase() + field.slice(1)
                  }`,
                )}
                value={
                  editedInfo[field as keyof UserDto] ??
                  userInfo[field as keyof UserDto]
                }
                isEditing={editingField === field}
                onChange={handleEditChange}
                field={field}
                onEditClick={() => {
                  setEditingField(field);
                  setEditedInfo({[field]: userInfo[field as keyof UserDto]});
                }}
              />
            ))}
            <UserInfoRow
              label={t('dashboardUserMeIsSuspension')}
              value={userInfo.isSuspension}
              isEditing={false}
              field='isSuspension'
              editable={false}
            />
            {editingField && (
              <Box
                sx={{mt: 4, display: 'flex', justifyContent: 'center', gap: 2}}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSave}
                >
                  {t('save')}
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => setEditingField(null)}
                >
                  {t('cancel')}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography variant='body1' color='text.secondary' align='center'>
            Loading...
          </Typography>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default function DashboardUserMe() {
  return <Dashboard element={<AccountContent />} />;
}
